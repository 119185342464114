<template >
  <div class="container" id="imprimir" >
    <div class="content" style="margin-top:20px">
      <header class="d-flex align-items-md-center justify-content-between">
        <img src="@/assets/logo.png" alt="[logo]" style="width:250px; padding:20px;" />
        <div>
          <h4 class="text-center">RELATÓRIO DE COMISSÕES DAS AGÊNCIAS</h4>
          <p>Período de: {{convertDate(data.dataIni)}} até {{convertDate(data.dataEnd)}}</p>
        </div>
      </header>
      <br />
      <br />
      <div style="margin-bottom:20px">
        <header class="d-flex align-items-md-center" style="background:#eee;padding:10px 0 0 10px">
          <h5 class="text-center">COMISSÕES POR AGÊNCIAS</h5>
        </header>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="text-left">Agência</th>
              <th scope="col" class="text-left">Vendas R$</th>
              <th scope="col" class="text-left">Encomendas R$</th>
              <th scope="col" class="text-left">Total R$</th>
              <th scope="col" class="text-left" style="width:15%">Comissão R$</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(totais,i) in dados" :key="i">
              <td class="text-left">{{ totais.agencia }}</td>
              <td class="text-left">{{ totais.total_vendas }}</td>
              <td class="text-left">{{ totais.total_encomendas }}</td>
              <td class="text-left">{{ totais.total_liquido }}</td>
              <td class="text-left" style="width:10%">{{ totais.total_comissao }}</td>
            </tr>            
          </tbody>
          <tbody>
           <tr>
              <td class="text-left"><b>TOTALIZADORES:</b> </td>
              <td class="text-left"><b>{{ dados[dados.length -1].tVendas }}</b></td>
              <td class="text-left"><b>{{ dados[dados.length -1].tEncomendas }}</b></td>
              <td class="text-left"><b>{{ dados[dados.length -1].tGeral }}</b></td>
              <td class="text-left" style="width:10%"><b>{{ dados[dados.length -1].tComissoes }}</b></td>
            </tr>         
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dados","data"],
  data() {
    return {
      agencia: null
    };
  },
  methods: {
    convertDate(date) {
      return date
        .split("-")
        .reverse()
        .join("/");
    }
  }
};
</script>
