<template>
  <v-container class="size mt-9">
    <v-subheader class="title mb-4 text-uppercase pa-0">
      <v-icon class="mr-3" @click="$router.back()">mdi-arrow-left</v-icon>
      {{ "Consultar Comissões das Agências" }}
    </v-subheader>
    <v-card class="mb-10">
      <v-toolbar color="primary" dark elevation="0" dense>
        <v-toolbar-title class>PESQUISAR</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row dense class="text-center">
          <v-col cols="12" sm="3">
            <calendar
              :label="'Data'"
              @dateValue="[(search.dataIni = $event), loadValues()]"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <calendar
              :label="'Data'"
              @dateValue="[(search.dataEnd = $event), loadValues()]"
            />
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn
              tile
              depressed
              color="success"
              style="position: relative; top: 14px"
              @click="loadValues()"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn
              tile
              depressed
              color="warning"
              style="position: relative; top: 14px"
              @click="$htmlToPaper('imprimir')"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <template v-if="items.length > 0">
      <v-card class="text-center mb-7" v-for="(item, i) in items" :key="i">
        <v-toolbar color="primary" dark class="mb-3" flat dense>
          <h4>{{ item.agencia }}</h4>
        </v-toolbar>
        <v-card-actions>
          <v-row>
            <v-col cols="12" sm="3">
              <b>Valor das Vendas R$: <br />{{ item.total_vendas }}</b>
            </v-col>
            <v-col cols="12" sm="3">
              <b>Valor das Encomendas R$: <br />{{ item.total_encomendas }}</b>
            </v-col>
            <v-col cols="12" sm="3">
              <b>Valor Total R$: <br />{{ item.total_liquido }}</b>
            </v-col>
            <v-col cols="12" sm="3">
              <b>Valor da Comissão R$: <br />{{ item.total_comissao }}</b>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
    <template v-else>
      <v-card class="text-center">
        <v-card-title>
          <v-icon color="black" class="mr-5"
            >mdi-emoticon-neutral-outline</v-icon
          >Não foram encontrados resultados para a busca tente outros termos.
        </v-card-title>
      </v-card>
    </template>
    <report :dados="items" :data="search" style="display: none" />
  </v-container>
</template>

<script>
import calendar from "@/components/Calendars.vue";
import report from "@/components/printers/reports/CommissionsAgency.vue";
export default {
  components: {
    calendar,
    report,
  },
  data: () => ({
    search: {
      dataIni: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dataEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
    dialog: false,
    items: [],
    valid: true,
    fieldRules: [(v) => !!v || "Este campo é requerido"],
  }),
  created() {
    this.loadValues();
  },
  methods: {
    loadValues() {
      this.$store.dispatch("activeLoader", true);
      this.$http.post(`/comissoes/agencias`, this.search).then((r) => {
        this.items = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    convertDate(date) {
      return date.split("-").reverse().join("/");
    },
  },
};
</script>
